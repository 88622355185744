import React from 'react'
import logo from '../assets/logo.gif';
import mclaren from '../assets/mclaren.png';
import fokus from '../assets/fokus.png';
import verifikasi from '../assets/verifikasi.png';

import cutloss from '../assets/cutloss.png';

const Hero = () =>{
    return (
        <header className="app">

          <h2 className="desc_2">
                 <span className="white_gradient">MEME</span> 
                 <br></br>
            </h2>
            <br></br>
            <img src={cutloss} alt="cutloss" width="30%" />
            <br></br>
            <img src={mclaren} alt="mclaren" width="70%" />
            <br></br>
            <img src={fokus} alt="fokus" width="40%" />
            <br></br>
            <img src={verifikasi} alt="verifikasi" width="40%" />
                     

            
        </header>

    )
}

export default Hero