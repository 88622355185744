import React from "react";

const Footer = () => {
  return (

  <div className="app">
    <footer className = "descFooter">
    $TITIT Token has no association with Timothy Ronald. This token is simply a meme token with no intrinsic value or expectation of financial return

    <br></br>
    2024 by $TITIT Token. All rights reserved
    <br></br>
    <br></br>

    </footer>
    </div>

  );
};

export default Footer;