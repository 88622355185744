import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/logo.gif';
import mclaren from '../assets/mclaren.png';

const Hero = () => {

  return (
    <main className="app">
      <div className="gradient" />
      <div className='fadeInUp-animation2' >

        <iframe
          width="75%"
          height="380"
          src="https://www.youtube.com/embed/dQw4w9WgXcQ"
          title="Rick Astley - Never Gonna Give You Up (Official Music Video)"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <h2 className="desc">
          <span className="white_gradient">1. Buat Wallet</span>
        </h2>
        <h2 className="desc">
          Download phantom atau wallet pilihan Anda dari Play Store. Pengguna desktop, download google chrome extension dengan masuk ke phantom
        </h2>

        <h2 className="desc">
          <span className="white_gradient">2. Beli SOL</span>
        </h2>

        <h2 className="desc">Beli SOL dari exchange seperti Binance</h2>
        <h2 className="desc">
          <span className="white_gradient">3. Buka Raydium</span>
        </h2>
        <h2 className="desc">Buka Raydium.io. Connect wallet anda.</h2>
        <h2 className="desc">
          <span className="white_gradient">4. Beli $TITIT</span>
        </h2>
        <h2 className="desc">Pilih menu swap dan swap SOL ke $TITIT.</h2>
        <h2 className="desc">
          <span className="white_gradient">5. Tahan $TITIT</span>
        </h2>
        <h2 className="desc">Tahan $TITIT sampai naik</h2>
        <br></br>
        <br></br>
      </div>
    </main>
  );
};

export default Hero;
