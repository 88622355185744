import React, { useState } from 'react';
import './App.css';
import { Helmet } from 'react-helmet';
import Hero from './comp/Hero';
import Demo from './comp/Demo';
import Header from './comp/Header';
import Footer from './comp/Footer';
import About from './comp/About';
import Cara from './comp/Cara';
import Meme from './comp/Meme';
import VisibilitySensor from 'react-visibility-sensor';

function App() {
  const [visibleAbout, setVisibleAbout] = useState(false);
  const [visibleCara, setVisibleCara] = useState(false);

  const handleChangeAbout = (isVisible) => {
    setVisibleAbout(isVisible);
  };

  const handleChangeCara = (isVisible) => {
    setVisibleCara(isVisible);
  };

  return (
    <main>
            <Helmet>
        <title>TITIT</title>
        <meta name="description" content="Timoti Token" />
      </Helmet>
      <div className="all">
        <Header />
        <Hero />
        <section id="about">
            <About />

        </section>
        <section id="cara">

            <Cara  />

        </section>
        <Meme />
        <Footer />
      </div>
    </main>
  );
}

export default App;
