import React from 'react'
import logo from '../assets/logo.gif';
import mclaren from '../assets/mclaren.png';

const Hero = () =>{
    return (
        <header className="app">
            <div className="gradient"/>
            <div className="gradient"/>



            <h1 className="head_text">
                <span className="purple_gradient">$TITIT</span>
            </h1>

            <h2 className="desc_2">
                <span className="purple_gradient">TImoTI Token</span>
            </h2>

            <img src={logo} alt="logo" className="logo" width= "40%"/>
            
            

           





            
        </header>

    )
}

export default Hero