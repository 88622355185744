import React from 'react';
import logo from '../assets/logo.png'; // Corrected file extension

const Header = () => {
  return (
    <nav className="header">

        <img src={logo} alt="Logo" width="50px"/> {/* Display the logo */}
        <div className="nav_links">
        <a href="#" className="nav_link ml-4">Home</a> {/* Add margin to the left */}
              </div>
        <div className="nav_links">
        <a href="#about" className="nav_link ml-4">About</a> {/* Add margin to the left */}
              </div>
        <div className="nav_links">
        <a href="#cara" className="nav_link ml-4">Cara Pembelian</a> {/* Add margin to the left */}
      </div>
    </nav>
  );
}

export default Header;
