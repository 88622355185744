import React from 'react'
import logo from '../assets/logo.gif';
import mclaren from '../assets/mclaren.png';
import kekuatan from '../assets/kekuatan.jpg';

const Hero = () =>{
    return (
        <header className="about-container">
                    <div className="fadeInUp-animation">
        <div className = "app">

            <br></br>
            <h2 className="desc_2">  
                <span className="white_gradient">ABOUT</span>

            </h2>
            <br></br>
                <img src={kekuatan} alt="kekuatan" width="30%" />
  

  
            <h2 className="desc">Dibangun di atas blockchain Solana, $TITIT memanfaatkan kecepatan tinggi dan biaya transaksi rendah dari Solana. Ini memastikan bahwa transaksi $TITIT cepat dan terjangkau, mendorong adopsi dan utilitas yang lebih luas.</h2>

                <br></br>
                <br></br>
                <h2 className="desc_2">
 
            <span className="white_gradient">CARA PEMBELIAN</span>
            
       </h2>  
            

        </div>
        </div>
       
        </header>

    )
}

export default Hero